import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// import PrivateHeader from '../components/private_components/HeaderPrivate';

const StaffLayout = () => {

  return (
    <div className='bg-masterDarkBG min-h-screen'>
      {/* <PrivateHeader /> */}
      <main className='container min-h-screen py-8 mx-auto'>
        <Outlet />
      </main>
    </div>
  );
};

export default StaffLayout;
