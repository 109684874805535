import React, {useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import PublicHeader from '../components/public_components/HeaderPublic';

const PublicLayout = () => {

  const { login, isAuthenticated, navigateAfterLogin } = useAuth();
  useEffect(() => {
    console.log('hit2')
    if (isAuthenticated) {
      const role = localStorage.getItem('userRole')
      if(role) {
        // setLoading(false);
        navigateAfterLogin();
      }   
    }
  }, [isAuthenticated]); 

  return (
    <div className='bg-gray-100 min-h-screen flex flex-col'>
    <PublicHeader />
      <main className='flex flex-grow'>
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;
