// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';  // Adjust the path if necessary

const StaffPrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  console.log('PrivateRoute: isAuthenticated =', isAuthenticated);
  const role = localStorage.getItem('userRole');

  if (isAuthenticated === null) {
    return <div>Loading...</div>;  // Optionally, you can add a loading spinner here
  }

  if (!isAuthenticated || role !== 'staff') {
    console.log("User is not authenticated, redirecting to /login");
    return <Navigate to="/" />; // Redirect to login if not authenticated
  }

  console.log("User is authenticated, rendering protected route");
  return <Outlet/>; // Render the protected route if authenticated
  // return children
};

export default StaffPrivateRoute;
