import React from 'react';
import { Link } from 'react-router-dom';

const MemberQuestionaire = () => {
  console.log('Rendering Dashboard component');
  return (
    <div>
      
      <div className='flex flex-col max-w-6xl mx-auto'>
        {/* <div className='px-24 py-8  border border-borderc rounded w-1/3 hover:bg-blue-300 mr-6'>players</div> */}
        {/* <div className='px-24 py-8 border w-1/4 bg-blue-500 hover:bg-blue-300'>outstanding</div> */}
        {/* <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 mx-12'>create mandate</div>

        <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 ml-6'>create mandate</div> */}
        <div className='border border-gray-300 bg-white rounded-2xl mb-10'>
          <div className='border-b border-gray-300 px-5 py-5'>
            <h1 className='text-3xl mb-8 font-medium text-gray-900'>Pine Villa Youth Football Club</h1>
            <h1 className='text-xl font-medium text-gray-900'>PLAYER REGISTRATION FORM 2023/24</h1>
          </div>
          <div className='py-3 px-5'>
            <h1 className='text-red-600'>* Indicates required question</h1>
          </div>
          
          {/* <h1 className='text-xl mb-8 text-gray-900'>You have no outstanding task to complete</h1> */}
        </div>

          <div>
            <form>
              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Player Full Name  <span className='text-red-600'>*</span></h1>                  
                  <input className='border-b border-gray-400 ' placeholder='Your answer'></input> 
                </div>         
                               
              </div>

              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Emergency Contact Phone Number
                  <span className='text-red-600'>*</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>

              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Parent/Guardian Phone Number

                  <span className='text-red-600'>*</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>


              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Parent/Guardian Consent
                  <span className='text-red-600'>*</span></h1>  
                  <p className='mb-2'>The following items of consent are required as a condition of membership with the club:</p>
                  <p className='mb-2'>1.	Provision of equipment.	
                  It is a condition that your child wears the relevant equipment when playing in matches, or training sessions, ie – suitable footwear, shin pads and warm clothing (in cold weather). Otherwise he/she will not be allowed to play.</p>
                  <p className='mb-2'>2.	Collection.	
                  Please ensure that your child is picked up promptly after each session by yourself or someone known to him/her.</p>
                  <p className='mb-2'>3.	Medical attention	
If medical attention is needed which requires parental consent, we would attempt to contact parents via the information above. In the event of emergency treatment, an authorised leader could sign the consent for treatment.
We will only process details of the medical history with your consent.
“Being the parent/legal guardian of the above player I give my permission that in the event of an emergency the authorised leader could sign the “consent for treatments” forms.” (Please advise your group leader, in writing, of any medical condition or on-going treatment of which we should be aware, or any treatment, which may be necessary during the time your child is in our care.)</p>
                  <p className='mb-2'>4.	Payment of fines	
Should your child receive a yellow card during a game the LFA will issue a fine of £10.00. If your child receives a red card the LFA will issue a fine of £25.00 (although this could increase depending on the nature of the offence).  These fines are payable by the parent or guardian and not the Club.. Failure to pay the fine could result in the associated player being suspended from taking part in competitive matches. Should any supporter be charged with improper conduct the LFA and/or the League may issue the Club with a fine.  Any such fine will be payable by the supporter in question. Failure to pay the fine could result in the associated player being asked to leave the Club. If a charge is disputed an appeal may be lodged.  Please refer to the Club Secretary for further details of the procedure.
“I accept responsibility and agree to pay any fines issued by the LFA/League.”</p>
                  <p className='mb-2'>5.	Travel	
Your child may be driven to fixtures in a car driven by an authorised person. If you agree to this method of transportation, please give your consent below. If you disagree to this, then it will be your responsibility to transport your child to away venues. The responsibility of the authorised person is from the home ground and back, after the event. Parents will be required to collect their child from a designated collection point.
“I agree to the transportation of the above player.”
</p>
                  <p className='mb-2'>6.	Payment of subscriptions	
                  It is a condition of being registered to play and train with the club that subscriptions be paid upfront or by standing order on a monthly basis to the clubs designated account. Failure to meet the monthly subscription will avoid your child’s registration with the club.</p>
                  <p className='mb-2'>7.	Kit & equipment	
                  All football kits, tracksuits, rain jackets, kit bags and training wear which is supplied to your child is the property of Pine Villa JFC. It is a condition that should your child leave the Club that all kit supplied is returned to the relevant manager or Club Official. If you do not fulfil this obligation you will be liable to pay the full amount for all non-returned items and your child could be precluded from signing for another team.</p>
                  <p className='mb-2'>8.	Code of conduct	
                  It is a condition of being registered with the club that all Managers/Coaches, Players, Parents/Guardians and associated supporters will follow the appropriate FA Code of Conduct. Any breaches of the Code of Conduct will be dealt with at the discretion of the Disciplinary Committee.</p>
                  <p className='mb-2'>9.	Media & Marketing info	
Pine Villa Football Club wishes to promote participation in grass-roots football and may use images taken by the club’s nominated photographers on the Pine Villa Football Club website and/or the Pine Villa Football Club Facebook and Twitter pages. Some images may be used by the Junior Section Committee to promote our football club. No personal information, such as names, will be used in any publications unless express consent is given. Consent can be withdrawn at any time in writing, to the Junior Section Committee.
We will only send you direct marketing if you are an existing member, participant or other associated individual and you have not previously objected to this marketing, or, you have actively provided your consent.
“I hereby consent to the collection and appropriate use of my child’s personal images by photography and agree to be contacted for the purposes of direct marketing.”</p>
                  <p className='mb-2'>10.	Social networking	
                  Should your child participate in Social Networking, (i.e Facebook, Twitter) UNDER NO CIRCUMSTANCES should negative comments be posted which criticise our opponents or referees/officials. If any player is found guilty of this they will face disciplinary action by the club and may be reported to the LFA, who will then take appropriate disciplinary action against your Child. It is your (parent/guardian’s) responsibility to monitor your child whilst using the internet. The Club will not accept liability for any disciplinary charge your child may incur.  </p>
                  
                  <div className='flex items-center mt-5'>
                    <input className='w-6 h-6 mr-4' 
                      type="checkbox" 
                      // checked={isChecked} 
                      // onChange={handleCheckboxChange} 
                    />
                    <p className='text-xl'>I agree</p>
                  </div>
                  
                  {/* <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input>  */}
                </div>         
                               
              </div>

              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Childs ID to be shown to Club Secretary

                  <span className='text-red-600'>*</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>

              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Please upload a current photo of your child .This will be attached to the FAN number and used by the FA on the official squad list.
                  <h2></h2>
                  <span className='text-red-600'>*</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>

              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Please visit the link below to view the club policies.
                  <span className='text-red-600'>*</span></h1> 
                  <a 
                    href='https://www.pinevillayouthfc.com/info' 
                    target='_blank'
                    className='text-blue-500 hover:text-blue-700'
                    >
                  https://www.pinevillayouthfc.com/info</a>                 
                  
                </div>         
                               
              </div>

            </form>
          </div>
        
        
        <div className='bg-gray-200 p-10 rounded-2xl'>
          <h1 className='text-3xl mb-8 font-medium text-gray-900'>Accoutn detais page</h1>
          <h1 className='text-xl mb-8 text-gray-900'>box for details of parent/gaurdian</h1>
          <h1 className='text-xl mb-8 text-gray-900'>box for details of each player, eahc player gets a box
          whiting each players box will be soem shrot info and also a link ot hat paeyr socmpeterd 
          memebr quetsionnaie plus the meiducal questionnai</h1>

          <h1 className='text-3xl mb-8 font-medium text-gray-900'>place to add a player</h1>
        </div>
        <div className='bg-gray-200 p-10 rounded-2xl mb-12'>
          <h1 className='text-3xl mb-8 font-medium text-gray-900'>This will be on dashbaord</h1>
          <h1 className='text-3xl mb-8 font-medium text-gray-900'>Welcome name of parent</h1>
          <h1 className='text-xl mb-8 text-gray-900'>You have no outstanding task to complete</h1>
        </div>
        
        <div className='bg-gray-200 p-10 rounded-2xl'>
          <h1 className='text-3xl mb-8 font-medium text-gray-900'>Accoutn detais page</h1>
          <h1 className='text-xl mb-8 text-gray-900'>box for details of parent/gaurdian</h1>
          <h1 className='text-xl mb-8 text-gray-900'>box for details of each player, eahc player gets a box
          whiting each players box will be soem shrot info and also a link ot hat paeyr socmpeterd 
          memebr quetsionnaie plus the meiducal questionnai</h1>

          <h1 className='text-3xl mb-8 font-medium text-gray-900'>place to add a player</h1>
        </div>
        
      </div>
    </div>
  );
};

export default MemberQuestionaire;
