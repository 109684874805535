import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import MemberHeader from '../components/member_components/HeaderMember';
import MemberSidebar from '../components/member_components/MemberSidebar';

const MemberLayout = () => {

  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  

  

  return (

    // <div className='text-gray-600 min-h-screen w-full'>
    <div class="flex h-screen">
      {/* <MemberHeader /> */}


      {/* <div className='flex'> */}
      <div class="">
        <MemberSidebar/>
      </div>
        {/* <MemberSidebar/> */}
        {/* <main className='w-full min-h-screen p-6 ml-[250px] overflow-x-auto'>
          <Outlet />
        </main> */}

        <div class="flex-1 bg-gray-100 p-8 overflow-y-auto">
          <Outlet />
        </div>

    </div>
  );
};

export default MemberLayout;
