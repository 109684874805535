import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import AdminHeader from '../components/admin_components/HeaderAdmin';
import AdminSidebar from '../components/admin_components/SidebarAdmin';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const AdminLayout = () => {

  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  // useEffect(()=>{
  //   if(!isAuthenticated){
  //     navigate('/');
  //   }
  // })
  

  return (
    // <div className='bg-slate-950 text-text-Gray min-h-screen'>
    //   <AdminHeader />
    //   <main className='container min-h-screen py-8 mx-auto'>
    //     <Outlet />
    //   </main>
    // </div>
    <div className='bg-gray-100 text-gray-600 min-h-screen w-full'>
      <AdminHeader />


      <div className='flex'>
        <AdminSidebar/>
        <main className='w-full min-h-screen p-6 overflow-x-auto'>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
