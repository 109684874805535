import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  console.log('Rendering Dashboard component');
  return (
    <div>
      
      <div className='flex flex-col'>
        {/* <div className='px-24 py-8  border border-borderc rounded w-1/3 hover:bg-blue-300 mr-6'>players</div> */}
        {/* <div className='px-24 py-8 border w-1/4 bg-blue-500 hover:bg-blue-300'>outstanding</div> */}
        {/* <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 mx-12'>create mandate</div>

        <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 ml-6'>create mandate</div> */}
        <div className='bg-headerBar p-16 rounded-2xl'>
          <h1 className='text-6xl mb-8 text-gray-300'>Dashboard Under Construction</h1>
          <h1 className='text-3xl mb-8 text-gray-300'>Go to memberships section</h1>
        </div>
        

      </div>
    </div>
  );
};

export default Dashboard;
