import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SquareCheck, Filter } from 'lucide-react';
import { useFetchWithToken } from '../../services/api';

const Memberships = () => {

    const fetchWithToken = useFetchWithToken();
    // const [playerTableData, setPlayerTableData] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [parents, setParents] = useState([]);
    const [editStates, setEditStates] = useState({}); 
    const [editTeamStates, setEditTeamStates] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [players, setPlayers] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [filters, setFilters] = useState({ team: '', age: null });
    const [paginationInfo, setPaginationInfo] = useState({ next: null, previous: null });
    const [playerAges, setPlayerAges] = useState([])
    const teams = {
      // tbd: 'TBD',
      u6s: 'U6s',
      u7s: 'U7s',
      u8s: 'U8s',
      u9s: 'U9s',
      u10s: 'U10s',
      u11s: 'U11s',
      u12s: 'U12s',
      u13s: 'U13s',
      u14s: 'U14s',
      u15s: 'U15s',
      u16s: 'U16s',
    };

    const [showGoCardless, setShowGoCardless] = useState(false);
    
    const handleShowGoCardless = () => {
      setShowGoCardless(!showGoCardless)
    }


    const fetchGoCardlessCustomers = async () => {
        try {
          // Make a request to the GoCardless API endpoint in your Django backend
          const response = await fetchWithToken('/api/gocardless/customers/', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          console.log(response);
          // Parse the JSON data from the response
          const data = await response.json();
          console.log('GoCardless customers data:', data); // Log the data to verify its structure
          
          // Step 3: Update the state with fetched data
          setCustomers(data.GoCardlessCustomers);  // Assuming 'data' has a 'customers' property
          const initialEditStates = {};
          data.GoCardlessCustomers.forEach((customer) => {
            initialEditStates[customer.id] = customer.parent_id === null; // Editable if parent_id is null
          });
          setEditStates(initialEditStates);
    
        } catch (error) {
          console.error('Error fetching GoCardless customers data:', error);
        }
      };

    const fetchParents = async () => {
        try {
        const response = await fetchWithToken('/api/parents/');  // Replace with actual endpoint to fetch parents
        if (!response.ok) {
            throw new Error('Failed to fetch parents');
        }
        const data = await response.json();
        console.log("parents data", data);
        setParents(data);  // Set parent data into state
        } catch (error) {
        console.error('Error fetching parents:', error);
        }
    };

    const handleParentChange = async (customerId, parentId) => {
      try {
        const response = await fetchWithToken(`/api/gccustomers/${customerId}/link_customer_to_parent/`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            // 'X-CSRFToken': getCsrfToken(),  // Add CSRF token if needed for Django backend
            },
            body: JSON.stringify({ parent_id: parentId })
        });

        if(response.ok) {          
          fetchPlayers();
          fetchGoCardlessCustomers();
          setEditStates((prevState) => ({
            ...prevState,
            [customerId]: false // Lock the dropdown once a parent_id is set
          }));
        } else {
            throw new Error('Failed to update parent');
        }
      } catch (error) {
      console.error('Error updating parent:', error);
      }
    };

    const handleTeamChange = async (playerId, team) => {
      console.log('Changing team for player ID:', playerId, 'to:', team);
      try {
        const response = await fetchWithToken(`api/players/${playerId}/select_players_team/`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          // 'X-CSRFToken': getCsrfToken(),  // Add CSRF token if needed for Django backend
          },
          body: JSON.stringify({ "team_current": team })
        })
        if(response.ok) {          
          fetchPlayers();
          // fetchGoCardlessCustomers();
          // setEditTeamStates((prevState) => ({
          //   ...prevState,
            //what to put ehre
          // }));
        } else {
            throw new Error('Failed to update parent');
        }
        

      } catch (error) {
        console.error('Error updating team:', error);
      }
      
    };

    

      const fetchPlayers = async () => {

        const teamParam = filters.team ? `&team=${filters.team}` : '';
        const ageParam = filters.age && filters.age !== null ? `&age=${filters.age}` : '';
        setPlayers([]);
        const response = await fetchWithToken(`/api/membership-page/?page=${currentPage}${teamParam}${ageParam}`);
        const data = await response.json();
        console.log("pagetable:", data);
        
        setPlayers(data.results);  // Set players for the current page
        setPlayerAges(data.ages_list)
        setTotalPages(Math.ceil(data.count / 15));  // Calculate total pages
        setPaginationInfo({ next: data.next, previous: data.previous });

        const initialEditTeamStates = {};
          data.results.forEach((player) => {
            initialEditTeamStates[player.player_id] = player.team_current === "tbd"; // Initially Editable if current_team is equal to TBD, returns boolean true value to key, key is player.id
          });
          setEditTeamStates(initialEditTeamStates);
      };

      useEffect(() => {
        // i probably need a promise.all here
        fetchGoCardlessCustomers();
        fetchParents();
      }, [])

      useEffect(() => {
        fetchPlayers();
    }, [currentPage, filters]);

      const handleTeamFilter = (team) => {
        setFilters({ ...filters, team });
        setCurrentPage(1);  // Reset to page 1 when filter changes
      };
    
      // Handle age filter
      const handleAgeFilter = (age) => {
        setFilters({ ...filters, age });
        setCurrentPage(1);
      };

      const renderPaginationControls = () => (
        <div className="pagination-controls py-1 px-5 w-full flex text-gray-200 bg-white">
          <div className='w-1/3 flex items-center'>
          {paginationInfo.previous && (
            <button onClick={() => setCurrentPage(currentPage - 1)} className="text-gray-500 hover:text-indigo-600">
              Previous
            </button>
          )}
          </div>
          
          <div className="w-1/3 text-center space-x-2">
            {/* Render all page numbers */}
            {[...Array(totalPages)].map((_, i) => (
              <button
                key={i} // Correct the key to use 1-based indexing
                onClick={() => setCurrentPage(i + 1)} // Set the clicked page number as current
                className={`${currentPage === i + 1 ? 'text-white bg-headerBar px-2 rounded' : 'text-gray-600 hover:text-indigo-400'}`}
              >
                {i + 1}
              </button>
            ))}
          </div>
          
          <div className='w-1/3 flex items-center justify-end'>
          {paginationInfo.next && (
            <button onClick={() => setCurrentPage(currentPage + 1)} className="text-gray-600 hover:text-indigo-600">
              Next
            </button>
          )}
          </div>

        </div>
      );

      
      // Function to toggle edit state for specific customer
      const toggleEdit = (customerId) => {
        setEditStates((prevState) => ({
          ...prevState,
          [customerId]: !prevState[customerId], // Toggle the edit state for the specific customer
        }));
      };

      const toggleTeamEdit = (playerId) => {
        setEditTeamStates((prevState) => ({
          ...prevState,
          [playerId]: !prevState[playerId], // Toggle the edit state for the specific customer
        }));
      };

      const handleFetchMandates = async () => {
        try {
          const response = await fetchWithToken('api/mandate-data/');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log('raw-Mandates Data:', data);  // Log the data to the console
        } catch (error) {
          console.error('Error fetching raw mandate data:', error);
        }
      };

  return (
    <div>

      
<div>
      <div class="relative mb-12 overflow-x-auto border border-gray-300 rounded">
        <table class="min-w-full whitespace-nowrap text-gray-600 rounded overflow-hidden text-sm text-left bg-white">
          <thead class="text-xs bg-headerBar uppercase border-b border-gray-200">
            <tr className='text-white'>
              <th className="px-4 py-3 border-r border-gray-500">No.</th>
              <th class="px-6 py-3 border-r border-gray-500">Player Name</th>

              <th class="px-6 py-3 border-r border-gray-500">
                <div className='flex'>
                <div className='flex-1'>Age</div>
                  {/* <Filter size={16}/> */}
                  <select onChange={(e) => handleAgeFilter(e.target.value)} className="border border-gray-300 ml-2 bg-sideNavBack rounded text-gray-600">
                    <option value="">All</option>
                    {[...playerAges].map((age, i) => (
                      <option key={i} value={age}>{age}</option>
                    ))}
                  </select>
                </div>
              </th>

              <th className='px-6 py-3 border-r border-gray-500'>
                <div className='flex flex-1'>
                  <div className='flex-1'>Team</div>
                  <select onChange={(e) => handleTeamFilter(e.target.value)} className="border border-gray-300 ml-2 bg-sideNavBack rounded text-gray-600">
                    <option value="">All</option>
                    <option value="tbd">TBD</option>
                                    {Object.keys(teams).map((teamKey) => (
                                      <option key={teamKey} value={teamKey}>
                                        {teams[teamKey]}
                                      </option>
                                    ))}
                  </select>
                </div>
              </th>

              <th class="px-6 py-3 border-r border-gray-500">Parent/Guardian</th>
              <th class="px-6 py-3 border-r border-gray-500">GC CID</th>
              <th class="px-6 py-3 border-r border-gray-500">Sub Status</th>
              <th class="px-6 py-3 border-r border-gray-500">Parent Email</th>
              {/* <th class="px-6 py-3 border-r border-gray-200">Mandate Url</th> */}
            </tr>
          </thead>
          <tbody>                       
              {players.map((player, index) => {
                {/* const isTeamEditable = true; // Assuming edit mode is always on for this example
                const isTeamSelected = Boolean(player.team_current !== 'tbd'); */}
                const isTeamEditable = editTeamStates[player.player_id] || false;
                console.log(isTeamEditable, player.child_name, player.player_id); // Get the edit state for this customer
                const isTeamSelected = Boolean(player.team_current !== 'tbd');
                return (
                  <tr key={player.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="px-4 py-2 whitespace-nowrap border-r border-gray-200">{index + 1}</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">{player.child_name}</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">{player.player_age ? player.player_age : 'Unknown'}</td>
                    {/* <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">
                      <select className={`border p-1 border-gray-200 bg-black rounded ${isTeamSelected ? 'bg-slate-900 text-white' : 'bg-black'}`}
                        value={player.team_current || 'tbd'} onChange={() => {}} disabled={!isTeamEditable}>
                        <option value="tbd">Select Team</option>
                        <option value="TeamA">Team A</option>
                        <option value="TeamB">Team B</option>
                      </select>
                    </td> */}
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">
                                    <select className={`border p-1 border-gray-200 text-black rounded ${isTeamSelected ? 'bg-white' : 'bg-gray-100'}`}
                                      value={player.team_current || 'tbd'}
                                      onChange={(e) => handleTeamChange(player.player_id, e.target.value)}
                                      disabled={!isTeamEditable} // Disable dropdown unless in edit mode
                                    >
                                      <option value="tbd">Select Team</option>
                                      {Object.keys(teams).map((teamKey) => (
                                        <option key={teamKey} value={teamKey}>
                                          {teams[teamKey]}
                                        </option>
                                      ))}
                                    </select>

                                    {isTeamSelected && (
                                      <button
                                        className="ml-2 text-blue-500 hover:text-blue-700"
                                        onClick={() => toggleTeamEdit(player.player_id)}
                                      >
                                        {isTeamEditable ? "Lock" : "Edit"}
                                      </button>
                                    )}
                                  </td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">{player.parent_name}</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">{player.gc_id ? player.gc_id : 'Not linked'}</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">
                      <div className='flex'>
                      {player.sub_status === "active" ? (
                        <span className="flex items-center text-green-600">
                          <SquareCheck size={16} color="currentColor" className="mr-1" /> ACTIVE
                        </span>
                      ) : (
                        <span className="flex items-center text-red-600">
                          <SquareCheck size={16} color="currentColor" className="mr-1" /> PENDING
                        </span>
                      )}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">{player.parent_email}</td>
                    {/* <td className="px-6 py-2 border-r border-gray-200">{player.mandate_url ? player.mandate_url : 'N/A'}</td> */}
                  </tr>
                );
              })}
              {[...Array(15 - players.length)].map((_, i) => (
                <tr key={`empty-${i}`} className="text-gray-200 border-b border-gray-200">
                  <td className="px-4 py-2 text-slate-900 whitespace-nowrap border-r border-gray-200">{players.length + i + 1}</td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  <td className="px-6 py-2 whitespace-nowrap ">
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap ">
                  <select className='border border-white p-1 text-white rounded'
                    disabled // Disable dropdown unless in edit mode
                  >
                    <option value="tbd">Select Team</option>
                  </select>     
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  <td className="px-6 py-2 whitespace-nowrap ">
                    {/* <span className="flex items-center text-green-200">
                      <SquareCheck size={16} color="currentColor" className="mr-1" /> ACTIVE
                    </span> */}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  {/* <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">—</td> */}
                  {/* Empty columns */}
                </tr>
              ))}
          </tbody>
          
            
          
        </table>
        {renderPaginationControls()}
      </div>


    </div>


    <div>
      <button
        onClick={handleShowGoCardless}
        className="px-4 py-2 bg-headerBar text-gray-200 rounded hover:bg-indigo-900"
      >
        Gocardless Data
      </button>
    </div>

      
      
      



      {showGoCardless && (
        <>
        <div className='mt-24'>
      <button 
        onClick={handleFetchMandates} 
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      >
        Fetch Raw Mandate Data
      </button>
      </div>

      <div className='mt-24'>
      <h1 className='text-white'>GoCardlessData</h1>
  <div class="relative overflow-x-auto rounded">
    <table class="w-full border border-gray-200 text-sm text-left rtl:text-right">
      <thead class="text-xs text-gray-100 uppercase border-b border-gray-200 bg-slate-900">
        <tr className=''>
          <th scope="col" class="px-3 py-5 border-r border-gray-200">No.</th>
          <th scope="col" class="px-6 py-5 border-r border-gray-200">
            Mandate Holder
          </th>
          {/* <th scope="col" class="px-6 py-5 border-r border-gray-200">
            First Name
          </th>
          <th scope="col" class="px-6 py-5 border-r border-gray-200">
            Last Name
          </th> */}
          <th scope="col" class="px-6 py-5 border-r border-gray-200">
            Created At
          </th>
          <th scope="col" class="px-6 py-5 border-r border-gray-200">
            GC CID
          </th>
          <th scope="col" class="px-6 py-5 border-r border-gray-200">
            Email
          </th>
          <th scope="col" className="px-6 py-5 border-r border-gray-200">
            Parent
          </th>
          <th scope="col" className="px-6 py-5 border-r border-gray-200">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {customers.map((customer, index) => {

          const isEditable = editStates[customer.id] || false;
          console.log(isEditable); // Get the edit state for this customer
          const isParentSelected = Boolean(customer.parent_id);
          return (
          <tr
            key={index}
            className="bg-slate-900 text-gray-200 border-b border-gray-200 hover:bg-slate-800"
          >
            <td className="px-3 py-4 whitespace-nowrap border-r border-gray-200">
                {index +1}
            </td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">
                {`${customer.gc_given_name} ${customer.gc_family_name}`}
            </td>
            {/* <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">
              {customer.given_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">
              {customer.family_name}
            </td> */}
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">
              {customer.gc_created_at}
            </td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">
              {customer.gc_id}
            </td>
            <td className="px-6 py-4 border-r border-gray-200">
              {customer.gc_email}
            </td>
            <td className="px-6 py-3 whitespace-nowrap border-r border-gray-200">
                  <select className={`border p-1 border-gray-200 bg-black rounded ${isParentSelected ? 'bg-slate-900' : 'bg-black'}`}
                    value={customer.parent_id ? customer.parent_id : ''}
                    onChange={(e) => handleParentChange(customer.id, e.target.value)}
                    disabled={!isEditable} // Disable dropdown unless in edit mode
                  >
                    <option value="">Select Parent</option>
                    {parents.map((parent, index) => (
                      <option key={parent.id} value={parent.id}>{`${index + 1}. ${parent.parent_name}`}</option>
                    ))}
                  </select>

                  {isParentSelected && (
                    <button
                      className="ml-2 text-blue-500 hover:text-blue-700"
                      onClick={() => toggleEdit(customer.id)}
                    >
                      {isEditable ? "Lock" : "Edit"}
                    </button>
                  )}
            </td>
            <td className="px-6 py-4 border-r border-gray-200">
                {/* Optional: Add buttons for additional actions */}
            </td>
          </tr>
        );
        })}
      </tbody>
    </table>
  </div>
      </div>
      </>
    )}

    </div>
  );
};

export default Memberships;





// const [currentPage, setCurrentPage] = useState(1);
// const [filters, setFilters] = useState({ team: '', age: null });
// const [players, setPlayers] = useState([]);

// const fetchPlayers = async () => {
//     const response = await fetch(/api/players?page=${currentPage}&team=${filters.team}&age=${filters.age});
//     const data = await response.json();
//     setPlayers(data.players);
// };

// useEffect(() => {
//     fetchPlayers();
// }, [currentPage, filters]);