import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFetchWithToken } from '../../services/api';

const CreateMandate = () => {
  const fetchWithToken = useFetchWithToken();
  const [parentName, setParentName] = useState('');  //needs to be passed
  const [parentEmail, setParentEmail] = useState(''); //needs to be passes
  const [memberCount, setMemberCount] = useState(0); //needs to be passed especially for meta data, also if numebr is left at zero do not let user submit form
  const [members, setMembers] = useState([]);//needs to be passed//this is the init of empty array to store all player objects
  const [error, setError] = useState('');
  const [mandateLink, setMandateLink] = useState('');
  // const [day, setDay] = useState('');
  // const [month, setMonth] = useState('');
  // const [year, setYear] = useState('');

  // const handleDateChange = () => {
  //   if (day && month && year) {
  //     const formattedDate = `${year}-${month}-${day}`; // Format to YYYY-MM-DD
  //     onDateChange(formattedDate);
  //   }
  // };

  const handleMemberCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setMemberCount(count);

    // Adjust the members array length based on selected count
    const newMembers = Array.from({ length: count }, (_, i) => ({
        child_name: '',
        date_of_birth: '',
        notes: '',
        day: '',
        month: '',
        year: ''
    }));
    setMembers(newMembers);
  }; //thsi fucntion create the empty arrays after user ahs selected how many players they want to add

  const handleMemberChange = (index, field, value) => {
    const newMembers = [...members];
    newMembers[index][field] = value;

    // Recalculate date_of_birth when day, month, or year changes
    if (field === 'day' || field === 'month' || field === 'year') {
      const { day, month, year } = newMembers[index];
      if (day && month && year) {
        newMembers[index].date_of_birth = `${year}-${month}-${day}`; // Format to YYYY-MM-DD
      }
    }

    setMembers(newMembers);
  }; //this function updates each players data

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const payload = {
      parent: {
        parent_name: parentName,
        parent_email: parentEmail
      },
      player_count: memberCount,  // Including player_count here as well
      children: members.map(({ child_name, date_of_birth, notes }) => ({
        child_name,
        date_of_birth,
        notes
      })),
      // mandate: {
      //     // Include mandate-related fields if needed
      // }
    }
    console.log(payload);

    // Send formData to the backend API using fetch or axios
    try {
      const submitMandateCreationResponse = await fetchWithToken('api/create-mandate/', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload),
      })
      const data = await submitMandateCreationResponse.json();
      if (submitMandateCreationResponse.ok) {
        console.log('submit mandit initial creation success', data);
        setMandateLink(data.authorisation_url);
      } else {
        setError(data.message || 'An error occurred. Please try again.')
      }
    } catch (err) {
      setError('An error occurred. Please try again', err)
    }
  };

  return (
    <div>
        <div className='border border-borderc rounded'>
          <div className='p-4 border-b border-borderc'><h1 className='text-lg'>Create New Mandate</h1></div>
          <div className=''>
            
            <form onSubmit={handleSubmit}>
              <div className='border-b border-borderc p-4'>
              <div className='mb-4 flex flex-col'>
                <label className=' text-sm py-1'>Mandate Holder Name (hint: parent/gaurdian)</label>
                    <input 
                      className='bg-gray-50 border border-borderc p-2 mt-2 rounded text-sm'
                      placeholder='Name (hint: this name does not have to match the bank/mandate name)'
                      value={parentName}
                      onChange={(e) => setParentName(e.target.value)}
                      required
                    />                  
              </div>
              <div className='mb-4 flex flex-col'>
                <label className=' text-sm py-1'>Mandate Holder Email</label>
                    <input 
                      className='bg-gray-50 border border-borderc p-2 mt-2 rounded text-sm'
                      placeholder='Email'
                      value={parentEmail}
                      onChange={(e) => setParentEmail(e.target.value)}
                      required
                    />                  
              </div>
              <div className='mb-4 flex flex-col'>
                <label className='text-sm'>Number of Players Signing up:</label>
                <input
                    className='flex bg-gray-50 border border-borderc p-2 mt-2 rounded text-sm'
                    type="number"
                    min="0"
                    value={memberCount}
                    onChange={handleMemberCountChange}
                    required
                />
            </div>
            </div>

            <div className='flex flex-wrap mb-8'>
            {members.map((member, index) => (
                <div key={index} 
                    // className="w-1/3 border-r border-b border-borderc p-4"
                    className={`w-1/3 p-4 border-b border-borderc ${
                            (index + 1) % 3 !== 0 ? 'border-r' : ''
                        } ${index >= Math.floor((members.length - 1) / 3) * 3 ? '' : 'border-b'}`}
                        >
                    {/* <h4>Player {index + 1}</h4> */}
                    <div className=''>
                        <label>Player {index + 1} Name:</label>
                        <input
                            className='flex bg-gray-50 border border-borderc p-2 mt-2 rounded text-sm w-full mb-4'
                            type="text"
                            value={member.child_name}
                            onChange={(e) =>
                                handleMemberChange(index, 'child_name', e.target.value)
                            }
                            required
                        />
                    </div>
                    {/* <div>
                        <label>Age:</label>
                        <input
                            className='flex bg-gray-50 border border-borderc p-2 mt-2 rounded text-sm w-full mb-4'
                            type="number"
                            value={member.date_of_birth}
                            onChange={(e) =>
                                handleMemberChange(index, 'date_of_birth', e.target.value)
                            }
                            required
                        />
                    </div> */}

                    <div>
                      <label>Date of Birth:</label>
                      <div className='flex justify-between space-x-4 mb-4'>
                        <select
                          className='bg-gray-50 w-1/3 border border-borderc p-2 rounded'
                          value={member.day}
                          onChange={(e) => handleMemberChange(index, 'day', e.target.value)}
                        >
                          <option value="">Day</option>
                          {[...Array(31)].map((_, i) => (
                            <option key={i} value={String(i + 1).padStart(2, '0')}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                        <select
                          value={member.month}
                          className='bg-gray-50 w-1/3 border border-borderc p-2 rounded'
                          onChange={(e) => handleMemberChange(index, 'month', e.target.value)}
                        >
                          <option value="">Month</option>
                          {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((m) => (
                            <option key={m} value={m}>{m}</option>
                          ))}
                        </select>
                        <select
                          value={member.year}
                          className='bg-gray-50 w-1/3 border border-borderc p-2 rounded'
                          onChange={(e) => handleMemberChange(index, 'year', e.target.value)}
                        >
                          <option value="">Year</option>
                          {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((yr) => (
                            <option key={yr} value={yr}>{yr}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                        <label>Additional Notes:</label>
                        <textarea
                            className='flex bg-gray-50 border border-borderc p-2 mt-2 rounded text-sm w-full mb-4'
                            value={member.notes}
                            onChange={(e) =>
                                handleMemberChange(index, 'notes', e.target.value)
                            }
                        ></textarea>
                    </div>
                </div>
            ))}
            </div>


            <button className='p-4 px-12 mb-8 ml-4 flex bg-blue-200 border hover:bg-blue-500 hover:text-white border-borderc p-2 mt-2 rounded text-sm' type="submit">SUBMIT</button>

            </form>
          </div>
          
           {/* Display the mandate link if available */}
            {mandateLink && (
              <div>
                <p>Complete the mandate by clicking the link below:</p>
                <a href={mandateLink} target="_blank" rel="noopener noreferrer">{mandateLink}</a>
              </div>
            )} 


        </div>
        

    </div>
  );
};

export default CreateMandate;
