// src/components/Header.js
import React from 'react';
import { Link, NavLink} from 'react-router-dom';

const PublicHeader = () => {
  return (
    <header className=" p-4 bg-headerBar text-white">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-lg font-medium">
          PVFC Admin
        </div>
        <nav>
          <ul className="flex space-x-4">
            {/* <li>
              <NavLink to="/" className="hover:text-white">Home</NavLink>
            </li> */}
            {/* <li>
              <NavLink to="/member-login" className="hover:text-white">Members</NavLink>
            </li> */}
            <li>
              <NavLink to="/staff-login" className="hover:text-indigo-500">Login</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default PublicHeader;
