import React, { useEffect, useState } from 'react';
import { Link, Outlet, NavLink } from 'react-router-dom';
import { SquareCheck, Filter } from 'lucide-react';
import { useFetchWithToken } from '../../services/api';

const Clients = () => {

  return (
    <div>
      <div>
        <h1 className='text-headerBar font-medium text-4xl mb-2'>Memberships</h1>
        <p className='text-gray-500 mb-2'>
          Wiew all membership and payment information.
        </p>

        <div className='relative'>
          <div className='flex space-x-6 mb-6 '>
          <NavLink 
            to='memberships' 
            className={({ isActive }) => 
              ` py-2 border-b-2 px-4 z-10 ${isActive ? ' border-b-2 border-iconColor text-iconColor font-medium' : ' border-transparent'}`
            }
          >
            Players
          </NavLink>

          <NavLink 
            to='payments' 
            className={({ isActive }) => 
              ` py-2 border-b-2 px-4 z-10 ${isActive ? ' border-b-2 border-iconColor text-iconColor font-medium' : ' border-transparent'}`
            }
          >
            Payments
          </NavLink>

          {/* link for */}
          
          </div>  
          <hr className="absolute bottom-0 left-0 right-0 h-[2px] bg-gray-200" />
        </div>

      </div>
      <div>
      <Outlet />
      </div>
      
    </div>
  );
};

export default Clients;





