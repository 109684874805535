// src/components/Header.js
import React, { useState } from 'react';
import { Link, NavLink} from 'react-router-dom';
import { House, Handshake, Users } from 'lucide-react';
import useLogout from '../logout';
import pinevillalogo from '../../assets/pinevillalogo.png';

const AdminSidebar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLogout = useLogout();

  return (
  
    // <div className="">

      <div className=' text-gray-100 w-[250px] border-r border-gray-200'>
        <nav className='mx-auto '>
          <ul className="flex flex-col">
            <li>
              <NavLink to="/dashboard" 
                className={({ isActive }) =>
                  `py-3 px-6 flex font-medium items-center text-md hover:text-iconColor ${isActive ? 'text-iconColor bg-sideNavBack' : 'text-sectextGray '}`
                  }
              >
              {({ isActive }) => (
                <>
                <House 
                  size={24} 
                  color="currentColor" 
                  className={`text-greyishtext hover:text-white mr-1 ${isActive ? 'text-iconColor' : ''}`} 
                />
                Dashboard
                </>
              )}    
              </NavLink>
            </li>
            
            {/* <li>
              <NavLink to="/memberships" 
                className={({ isActive }) => 
                  `py-3 px-6 flex font-medium items-center text-md hover:text-iconColor ${isActive ? 'text-iconColor bg-sideNavBack' : 'text-sectextGray '}`
                }
              >
                {({ isActive }) => (
                  <>
                    <Handshake 
                      size={24} 
                      color="currentColor" 
                      className={`text-greyishtext hover:text-white mr-1 ${isActive ? 'text-iconColor' : ''}`} 
                    />
                    Memberships
                  </>
                )}
              </NavLink>
            </li> */}
            
            <li>
              <NavLink 
                to="/clients" 
                className={({ isActive }) => 
                  `py-3 px-6 flex font-medium items-center text-md hover:text-iconColor ${isActive ? 'text-iconColor bg-sideNavBack' : 'text-sectextGray '}`
                }
              >
                {({ isActive }) => (
                  <>
                    <Handshake 
                      size={24} 
                      color="currentColor" 
                      className={`text-greyishtext mr-1 ${isActive ? 'text-iconColor' : ''}`} 
                    />
                    Memberships
                  </>
                )}
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/staff" className={({ isActive }) =>`text-sectextGray flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                <Users size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Staff
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink to="/dashboard" className="text-sectextGray  text-sm hover:text-blue-700">Dashboard</NavLink>
            </li> */}

          </ul>
        </nav>
      </div>
      
    // </div>
  );
};

export default AdminSidebar;



{/* <li>
              <NavLink to="/memberships" 

                className={({ isActive }) =>`py-2 px-6 text-sectextGray flex items-center text-md hover:text-blue-700 ${isActive ? 'text-blue-700 bg-sideNavBack' : ''}`}>
                <Handshake size={24} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>
 
                Memberships
              </NavLink>
            </li> */}