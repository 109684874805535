import React from 'react';
import { Link } from 'react-router-dom';

const MemberMedical = () => {
  console.log('Rendering Dashboard component');
  return (
    <div>
      
      <div className='flex flex-col max-w-6xl mx-auto'>
        {/* <div className='px-24 py-8  border border-borderc rounded w-1/3 hover:bg-blue-300 mr-6'>players</div> */}
        {/* <div className='px-24 py-8 border w-1/4 bg-blue-500 hover:bg-blue-300'>outstanding</div> */}
        {/* <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 mx-12'>create mandate</div>

        <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 ml-6'>create mandate</div> */}
        <div className='border border-gray-300 bg-white rounded-2xl mb-10'>
          <div className='border-b border-gray-300 px-5 py-5'>
            <h1 className='text-3xl mb-4 font-medium text-gray-900'>Pine Villa Youth Football Club Medical Questionnaire</h1>
            <h1 className='text-xl mb-2 font-medium text-gray-900'>Medical Questionnaire - * this will be used for club info only*</h1>
            <h1 className='text-xl font-medium text-gray-900'>For: Player Name</h1>
          </div>
          <div className='py-3 px-5'>
            <h1 className='text-red-600'>* Indicates required question</h1>
          </div>
          
          {/* <h1 className='text-xl mb-8 text-gray-900'>You have no outstanding task to complete</h1> */}
        </div>

          <div>
            <form>
              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Player Full Name  <span className='text-red-600'>*</span></h1>                  
                  <input className='border-b border-gray-400 ' placeholder='Your answer'></input> 
                </div>         
                               
              </div>

              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Emergency Contact Name
                  <span className='text-red-600'> *</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>
              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Emergency Contact Phone Number
                  <span className='text-red-600'> *</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>


              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Doctors details - Name, Address, Phone number 


                  <span className='text-red-600'> *</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>

              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Relationship to child
                  <span className='text-red-600'> *</span></h1>                  
                  <input 
                  className='border-b border-gray-400 '
                  placeholder='Your answer'
                  ></input> 
                </div>         
                               
              </div>


              <div className='border border-gray-300 rounded-2xl mb-10 bg-white'>
                <div className='p-5'>
                  <h1 className='text-lg mb-8 font-bold text-gray-900'>Medical Profile
                  <span className='text-red-600'> *</span></h1>      
                  <div>
                    <p></p>
                  </div>            
                  <input 
                    type="radio" 
                    name="question" 
                    value="option2" 
                    // checked={selectedOption === 'option2'} 
                    // onChange={handleOptionChange} 
                    className="mr-2"
                  />
                </div>         
                               
              </div>


              

              

              

            </form>
          </div>
        
        
        
        
      </div>
    </div>
  );
};

export default MemberMedical;
