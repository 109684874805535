import React from 'react';
import { Link } from 'react-router-dom';

const MemberTasks = () => {
  console.log('Rendering Dashboard component');
  return (
    <div>
      
      <div className='flex flex-col mx-auto'>
        {/* <div className='px-24 py-8  border border-borderc rounded w-1/3 hover:bg-blue-300 mr-6'>players</div> */}
        {/* <div className='px-24 py-8 border w-1/4 bg-blue-500 hover:bg-blue-300'>outstanding</div> */}
        {/* <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 mx-12'>create mandate</div>

        <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 ml-6'>create mandate</div> */}
        <div className='bg-gray-200 p-10 rounded-2xl mb-12'>
          {/* <h1 className='text-3xl mb-8 font-medium text-gray-900'>This will be on dashbaord</h1> */}
          <h1 className='text-3xl mb-8 font-medium text-gray-900'>Welcome name of parent</h1>
          <h1 className='text-xl  text-gray-900'>You have no outstanding task to complete</h1>
        </div>
        
      </div>
    </div>
  );
};

export default MemberTasks;
