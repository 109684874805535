import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SquareCheck } from 'lucide-react';
import { useFetchWithToken } from '../services/api';

const CREATE_MANDATE_URL = process.env.REACT_APP_CREATE_MANDATE_URL;

const CreateMandate = () => {
    const fetchWithToken = useFetchWithToken();
    const [parentName, setParentName] = useState('');  //needs to be passed
    const [parentEmail, setParentEmail] = useState(''); //needs to be passes
    const [memberCount, setMemberCount] = useState(0); //needs to be passed especially for meta data, also if numebr is left at zero do not let user submit form
    const [members, setMembers] = useState([]);//needs to be passed//this is the init of empty array to store all player objects
    const [error, setError] = useState('');
    const [mandateLink, setMandateLink] = useState('');
    const [part2Visible, setPart2Visible] = useState(false)

  
    const handleMemberCountChange = (e) => {
      const count = parseInt(e.target.value, 10);
      setMemberCount(count);
  
      // Adjust the members array length based on selected count
      const newMembers = Array.from({ length: count }, (_, i) => ({
          child_name: '',
          date_of_birth: '',
          notes: '',
          day: '',
          month: '',
          year: ''
      }));
      setMembers(newMembers);
    }; //thsi fucntion create the empty arrays after user ahs selected how many players they want to add
  
    const handleMemberChange = (index, field, value) => {
      const newMembers = [...members];
      newMembers[index][field] = value;
  
      // Recalculate date_of_birth when day, month, or year changes
      if (field === 'day' || field === 'month' || field === 'year') {
        const { day, month, year } = newMembers[index];
        if (day && month && year) {
          newMembers[index].date_of_birth = `${year}-${month}-${day}`; // Format to YYYY-MM-DD
        }
      }
  
      setMembers(newMembers);
    }; //this function updates each players data
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');
  
      const payload = {
        parent: {
          parent_name: parentName,
          parent_email: parentEmail
        },
        player_count: memberCount,  // Including player_count here as well
        children: members.map(({ child_name, date_of_birth, notes }) => ({
          child_name,
          date_of_birth,
          notes
        })),
        // mandate: {
        //     // Include mandate-related fields if needed
        // }
      }
      console.log("test log",payload);
  
      // Send formData to the backend API using fetch or axios
      try {
        const submitMandateCreationResponse = await fetch(`${CREATE_MANDATE_URL}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(payload),
        })
        const data = await submitMandateCreationResponse.json();
        if (submitMandateCreationResponse.ok) {
          console.log('submit mandit initial creation success', data);

          setMandateLink(data.authorisation_url);
          setPart2Visible(true);
        } else {
          setError(data.message || 'An error occurred. Please try again.')
        }
      } catch (err) {
        setError('An error occurred. Please try again', err)
      }
    };
  
    return (
      <div className='flex flex-col justify-center items-center w-full my-4 mx-2'>

          {!part2Visible && (
            <div className='rounded-2xl bg-white border border-gray-300 max-w-5xl mx-auto w-full'>
            <div className='p-4 border-b border-gray-300 font-medium text-headerBar'><h1 className='text-xl'>Complete PVFC application: Step 1</h1></div>
            <div className=''>
              
              <form onSubmit={handleSubmit}>
                <div className='border-b border-gray-300 p-4'>
                <div className='mb-4 flex flex-col'>
                  <label className=' text-sm py-1 text-headerBar'>Mandate Holder Name (hint: parent/gaurdian)</label>
                      <input 
                        className='bg-white border border-gray-300 p-3 mt-2 text-gray-700 rounded'
                        placeholder='Name (hint: this name does not have to match the bank/mandate name)'
                        value={parentName}
                        onChange={(e) => setParentName(e.target.value)}
                        required
                      />                  
                </div>
                <div className='mb-4 flex flex-col'>
                  <label className=' text-sm py-1 text-headerBar'>Mandate Holder Email</label>
                      <input 
                        className='bg-white border border-gray-300 p-3 text-gray-700 mt-2 rounded'
                        placeholder='Email'
                        value={parentEmail}
                        onChange={(e) => setParentEmail(e.target.value)}
                        required
                      />                  
                </div>
                <div className='mb-4 flex flex-col'>
                  <label className='text-sm text-headerBar'>Number of Players Signing up:</label>
                  <input
                      className='flex bg-white p-3 border border-gray-300 mt-2 text-gray-700 rounded'
                      type="number"
                      min="0"
                      value={memberCount}
                      onChange={handleMemberCountChange}
                      required
                  />
              </div>
              </div>
  
              <div className='flex flex-col md:flex-row md:flex-wrap mb-8'>
              {members.map((member, index) => (
                  <div key={index} 
                      // className="w-1/3 border-r border-b border-borderc p-4"
                      className={`w-full md:w-1/3 p-4 border-b border-gray-300 ${
                              (index + 1) % 3 !== 0 ? 'border-r' : ''
                          } ${index >= Math.floor((members.length - 1) / 3) * 3 ? '' : 'border-b-2'}`}
                          >
                      {/* <h4>Player {index + 1}</h4> */}
                      <div className=''>
                          <label className='text-headerBar text-sm'>Player {index + 1} Name:</label>
                          <input
                              className='flex text-gray-700 text-gray-300 bg-white border border-gray-300 p-3 mt-2 rounded text-sm w-full mb-4'
                              type="text"
                              value={member.child_name}
                              onChange={(e) =>
                                  handleMemberChange(index, 'child_name', e.target.value)
                              }
                              required
                          />
                      </div>
  
                      <div>
                        <label className='text-headerBar text-sm'>Date of Birth:</label>
                        <div className='flex justify-between space-x-4 mb-4 mt-2'>
                          <select
                            className=' bg-white border border-gray-300 w-1/3 text-gray-700 p-3 rounded'
                            value={member.day}
                            onChange={(e) => handleMemberChange(index, 'day', e.target.value)}
                          >
                            <option value="">Day</option>
                            {[...Array(31)].map((_, i) => (
                              <option key={i} value={String(i + 1).padStart(2, '0')}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                          <select
                            value={member.month}
                            className='bg-white border border-gray-300 w-1/3 text-gray-700 p-3 rounded'
                            onChange={(e) => handleMemberChange(index, 'month', e.target.value)}
                          >
                            <option value="">Month</option>
                            {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((m) => (
                              <option key={m} value={m}>{m}</option>
                            ))}
                          </select>
                          <select
                            value={member.year}
                            className='bg-white border border-gray-300 w-1/3 text-gray-700 p-3 rounded'
                            onChange={(e) => handleMemberChange(index, 'year', e.target.value)}
                          >
                            <option value="">Year</option>
                            {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                            ))}
                          </select>
                        </div>
                      </div>
  
                      <div>
                          <label className='text-headerBar text-sm'>Additional Notes:</label>
                          <textarea
                              className='flex text-gray-700 bg-white border border-gray-300 p-3 mt-2 rounded text-sm w-full mb-4'
                              value={member.notes}
                              onChange={(e) =>
                                  handleMemberChange(index, 'notes', e.target.value)
                              }
                          ></textarea>
                      </div>
                  </div>
              ))}
              </div>
  
  
              <button className='p-4 px-12 mb-8 ml-4 flex bg-headerBar hover:bg-indigo-600 text-white p-2 mt-2 rounded ' type="submit">SUBMIT</button>
  
              </form>
            </div>
            
             {/* Display the mandate link if available */}
              {/* {mandateLink && (
                <div>
                  <p>Complete the mandate by clicking the link below:</p>
                  <a href={mandateLink} target="_blank" rel="noopener noreferrer">{mandateLink}</a>
                </div>
              )}  */}
  
  
          </div>
          )}
          
          
          {part2Visible && (
            <div className='rounded-2xl bg-white border border-gray-300 max-w-5xl mx-auto w-full'>
            <div className='p-4 border-b border-gray-300 text-headerBar font-medium'><h1 className='text-xl'>Complete PVFC application: Step 2</h1></div>
            <div className='text-headerBar p-4 border-b border-gray-300'>
              <p>Please follow the link below to complete the Direct Debit mandate.</p>
              <p>If you lose this link it will be sent to you in email also</p>
              
            </div>
            
             {/* Display the mandate link if available */}
              {/* {mandateLink && ( */}
                <div className='p-4 text-headerBar'>
                  <p className='text-lg mb-2 font-medium'>Complete the mandate using the link below:</p>
                  <a className='hover:text-indigo-600 break-all' href={mandateLink} target="_blank" rel="noopener noreferrer">{mandateLink}</a>
                </div>
              {/* )}  */}
  
  
          </div>
          )}
          
      </div>
    );
  };
  
  export default CreateMandate;