import React, {useState, useEffect} from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [waitingApprovalError, setWaitingApprovalError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, isAuthenticated, navigateAfterLogin } = useAuth();
  const [ role, setRole ] = useState('')
  // const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Client Side Validation
    // Make sure email and password are included
    if (!formData.email || !formData.password) {
        setError('Please enetr you email and password.');
        return;
    }
    // Make sure email is in proepr format, test againt regex format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)){
        setError('Please enter a valid email address.');
        return;
    }
    // Make sure email doesnt conatin any specific characters
    const forbiddenCharsEmail = /[<>]/;
    if (forbiddenCharsEmail.test(formData.email)){
        setError('Email address contains invalid characters');
        return;
    }
    // Make sure password is prper format, no dissallowed characters
    if (forbiddenCharsEmail.test(formData.password)){
        setError('Password should not contain certain characters: <, >.');
        return;
    }
    // Clear any existing errors
    setError('');
    setWaitingApprovalError('');

    try {
        const response = await fetch(`${LOGIN_URL}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...formData, rememberMe }), // Include rememberMe
            credentials: 'include', // Include credentials in the request
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Login successful:", data);
            localStorage.setItem('persistbeacon', data.persistbeacon);
            localStorage.setItem('userRole', data.userRole);
            // document.getElementById('hiddenForm').submit();
            login(); 
        } else {
            // const errorData = await response.json();
            // setError(errorData.detail || 'Login failed. Please try again.');
            // console.error("Login failed:", errorData);
            const errorData = await response.json();
            if (response.status === 403 && errorData.error_code === 'ACCOUNT_PENDING_APPROVAL') {
                setWaitingApprovalError('Your account is awaiting approval.');
            } else {
                setError(errorData.detail || 'Login failed. Please try again.');
                setLoading(false);
            }
            console.error("Login failed:", errorData)
        }
    } catch (error) {
        console.error('Error during login:', error);
        setError('An error occurred during login. Please try again.');
        setLoading(false);
    }
};

  useEffect(() => {
    console.log('hit2')
    if (isAuthenticated) {
      const role = localStorage.getItem('userRole')
      if(role) {
        setLoading(false);
        navigateAfterLogin();
      }   
    }
  }, [isAuthenticated]); 


  return (
      <div className="min-h-screen w-full flex justify-center">

          <div className="w-full max-w-md mt-16 mx-4 md:mt-32">
            {error && <p className="text-red-500">{error}</p>}
            {waitingApprovalError && <p className="text-green-500">{waitingApprovalError}</p>}

            { loading ? (
              <div className="flex justify-center">
                <svg
                  className="animate-spin h-10 w-10 text-blue-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>

              ):(
                <form onSubmit={handleSubmit} className="mb-4">
              {/* <h2 className="text-5xl text-gray-200 font-bold text-left mb-6">PVFC Admin</h2> */}
              <h2 className="text-5xl text-headerBar font-bold text-center mb-8">Log In</h2>
              <div className='mb-4'>
                <input
                  className="bg-white shadow appearance-none border border-gray-300 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline outline-cyan-500 focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete='email'
                />
              </div>
              <div className='mb-4'>
                <input
                  className="bg-white shadow appearance-none border border-gray-300 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline outline-cyan-500 focus:shadow-outline"
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  autoComplete='current-password'
                />
              </div>

              <div className='flex justify-between mb-2 '>
                <div>
                  <input 
                  className='appearance-none bg-white border border-gray-300 rounded h-4 w-4 checked:bg-headerBar checked:border-headerBar focus:ring-0 focus:outline-none'
                  type="checkbox" id="rememberMe" name="rememberMe" checked={rememberMe} onChange={handleRememberMeChange} />
                  <label className="text-gray-400 ml-2 " htmlFor="rememberMe">Remember Me</label>
                </div>
                {/* <div className='text-gray-400'><a>Forgot Password?</a></div> */}
              </div>

              <div>
                <button
                  className="bg-headerBar w-full hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit" 
                >
                  Log In
                </button>
              </div>
            </form>
            )}

            
          
          
          </div>
          {/* <form id="hiddenForm" method="POST" action="/login">
            <input type="hidden" name="email" value={formData.email} />
            <input type="hidden" name="password" value={formData.password} />
          </form> */}

 
      </div>
    );
};

export default Login;


// if (window.PasswordCredential) {
//   const cred = new PasswordCredential({
//     id: formData.email,
//     password: formData.password
//   });
//   navigator.credentials.store(cred);
// }
