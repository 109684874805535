// PrivateRoute.js
import React, {useState, useEffect} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';  // Adjust the path if necessary

const AdminPrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  console.log('PrivateRoute: isAuthenticated =', isAuthenticated);
  
  const [role, setRole] = useState(null);

  useEffect(() => {
    // Wait for isAuthenticated to be set, then check the role
    if (isAuthenticated) {
      const userRole = localStorage.getItem('userRole');
      setRole(userRole);
    }
  }, [isAuthenticated]);

  if (isAuthenticated === null || role === null) {
    return <div>Loading...</div>;  // Optionally, you can add a loading spinner here
  }
  // const role = localStorage.getItem('userRole');
  // 
  if (!isAuthenticated || role !== 'admin' ) {
    console.log("User is not authenticated, redirecting to /login");
    return <Navigate to="/" />; // Redirect to login if not authenticated
  }

  console.log("User is authenticated, rendering protected route");
  return <Outlet/>; // Render the protected route if authenticated
  // return children
};

export default AdminPrivateRoute;
