// src/components/Header.js
import React, { useState } from 'react';
import { Link, NavLink} from 'react-router-dom';
import { House, Handshake, Users } from 'lucide-react';
import useLogout from '../logout';
import pinevillalogo from '../../assets/pinevillalogo.png';

const MemberSidebar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLogout = useLogout();

  return (
  

      <div className='h-screen text-gray-100 w-[250px] bg-headerBar flex flex-col  justify-between border-r border-gray-200 py-12 px-6'>
        <div>
        <div className="text-lg font-medium flex items-center mb-4">
            <img src={pinevillalogo} className='h-12 mr-2'/>
            <h1>PVFC Members</h1>
          </div>
        <nav className='mx-auto '>
          <ul className="flex flex-col">
            {/* <li>
              <NavLink
                className={({ isActive }) =>
                  `py-3 px-2 flex font-medium items-center text-md hover:text-iconColor ${isActive ? 'text-iconColor ' : 'text-sectextGray '}`
                  }
              >
              {({ isActive }) => (
                <>
                <House 
                  size={24} 
                  color="currentColor" 
                  className={`text-greyishtext hover:text-white mr-1 ${isActive ? 'text-iconColor' : ''}`} 
                />
                Dashboard
                </>
              )}    
              </NavLink>
            </li> */}

            <li>
              <NavLink 
                to='members-dashboard'
                className={({ isActive }) => 
                  `py-3 px-2 flex font-medium items-center text-md hover:text-indigo-500 ${isActive ? 'text-indigo-500 ' : 'text-white '}`
                }
              >
                {({ isActive }) => (
                  <>
                    <Handshake 
                      size={24} 
                      color="currentColor" 
                      className={`text-greyishtext mr-1 ${isActive ? 'text-indigo-500' : ''}`} 
                    />
                    Account Details
                  </>
                )}
              </NavLink>
            </li>
            
            <li>
              <NavLink 
                to='/member-tasks'
                className={({ isActive }) => 
                  `py-3 px-2 flex font-medium items-center text-md hover:text-indigo-500 ${isActive ? 'text-indigo-500 ' : 'text-white '}`
                }
              >
                {({ isActive }) => (
                  <>
                    <Handshake 
                      size={24} 
                      color="currentColor" 
                      className={`text-greyishtext mr-1 ${isActive ? 'text-indigo-500' : ''}`} 
                    />
                    Tasks
                  </>
                )}
              </NavLink>
            </li>
            


          </ul>
        </nav>
        </div>
        
        <div>
          <button 
            onClick={handleLogout} 
            className="w-full px-4 py-2 hover:bg-indigo-800 bg-indigo-600 rounded ">
            Sign out
          </button>
        </div>
      </div>
      

  );
};

export default MemberSidebar;


