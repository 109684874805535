import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import AdminLayout from './layouts/AdminLayout';
import StaffLayout from './layouts/StaffLayout';
import PublicLayout from './layouts/PublicLayout';
import MemberLayout from './layouts/MemberLayout';
import PublicFrontPage from './public_pages/PublicFrontPage';
import Login from './public_pages/Login';
import Dashboard from './components/admin_components/Dashboard';
import Memberships from './components/admin_components/Memberships';
import CreateMandate from './components/admin_components/CreateMandate';
import MandateHolder from './components/admin_components/MandateHolder';
import Staff from './components/admin_components/Staff';
import MemberForm from './public_pages/MemberForm';
import MemberFormSuccess from './public_pages/MemberFormSuccess';
import MembersLogin from './public_pages/MembersLogin';
import Clients from './components/admin_components/Clients';
import PaymentsTable from './components/admin_components/PaymentsTable';
import AdminPrivateRoute from './services/AdminPrivateRoute';
import StaffPrivateRoute from './services/StaffPrivateRoute';
import MemberPrivateRoute from './services/MemberPrivateRoute';
import MembersDashboard from './components/member_components/MembersDashboard';
import MemberTasks from './components/member_components/MemberTasks';
import MemberQuestionaire from './components/member_components/MemberQuestionaire';
import MemberMedical from './components/member_components/MemberMedical';


function App() {
  return (
    <Router>
        <AuthProvider>
          <Routes>

            <Route element={<PublicLayout />}>
              <Route path="/" element={<Login />} />
              {/* <Route path="/" element={<PublicFrontPage />} /> */}
              {/* <Route path="/member-login" element={<MembersLogin />} /> */}
              <Route path="/staff-login" element={<Login />} />
              <Route path='new-member-form' element={<MemberForm />} />
              <Route path='form-success' element={<MemberFormSuccess />} />
              {/* <Route path="/register" element={<Register />} />
              <Route path="/invite/:link/validate" element={<ValidateInvite />} />
              <Route path="/awaiting-approval" element={<WaitingApproval />} /> */}
            </Route>
            
            <Route element={<AdminPrivateRoute />}>
              <Route element={<AdminLayout />}>
                {/* <Route path="/project" element={<Projects />} />
                <Route path="/projectinfo/:id" element={<ProjectPage />} /> */}
                <Route path="/dashboard" element={<Dashboard />} /> 

                <Route path="/clients" element={<Clients />}> 
                  <Route path="memberships" element={<Memberships/>} />
                  <Route path="payments" element={<PaymentsTable/>} />
                </Route>

                <Route path="/memberships" element={<Memberships />}> 
                
                </Route>



                <Route path="/staff" element={<Staff />} />
                <Route path="/create-mandate" element={<CreateMandate />} />
                <Route path="/mandate-holder" element={<MandateHolder />} />
              </Route>
            </Route>
            
            <Route element={<StaffPrivateRoute />}>
              <Route element={<StaffLayout />}>
                {/* <Route path="/project" element={<Projects />} />
                <Route path="/projectinfo/:id" element={<ProjectPage />} />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              </Route>
            </Route>

            <Route element={<MemberPrivateRoute />}>
              <Route element={<MemberLayout />}>
                <Route path="/members-dashboard" element={<MembersDashboard />} />
                <Route path="/member-tasks" element={<MemberTasks />} />
                <Route path="/member-questionnaire" element={<MemberQuestionaire />}/>
                <Route path="/member-medical-questionnaire" element={<MemberMedical />}/>
                {/* <Route path="/projectinfo/:id" element={<ProjectPage />} /> */}
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/" />} />

          </Routes>
        </AuthProvider>
      </Router>
  );
}

export default App;
