import React from 'react';
import { Link } from 'react-router-dom';

const MandateHolder = () => {
  return (
    <div>
      <h1>Dashboard</h1>
      <div className='flex flex-row justify-between'>
        <div className='p-24 border w-1/4 bg-blue-500 hover:bg-blue-300'>players</div>
        <div className='p-24 border w-1/4 bg-blue-500 hover:bg-blue-300'>outstanding</div>
        <div className='p-24 border w-1/4 bg-blue-500 hover:bg-blue-300'>create mandate</div>
      </div>
    </div>
  );
};

export default MandateHolder;
