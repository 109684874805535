// api.js
import { useAuth } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

let isRefreshing = false; //sets initial flas og if we are in rpocess fo refresh token or not
let refreshSubscribers = [] //initital empty array to store cllbacks in future while w eare waitinf for token to be refreshed

const onRefreshed = () => { //function to run all the callbacks we stored while waitign for toen to be refreshed
    refreshSubscribers.forEach((callback) => callback()); //need a deeper udnerstadnign what this lien is doing exactly
    refreshSubscribers=[]; //then we set the array to empty again for when next needed
}

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback); // Add a new request to the queue
};

async function refreshToken(logout, navigate) {
    try {
        // const response = await fetch('/auth/token/refresh/', {
        const response = await fetch(`${API_BASE_URL}/auth/token/refresh/`, {
            method: 'POST',
            credentials: 'include', // Include credentials (cookies) in the request
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.ok) {
            console.log('token refreshed');
            onRefreshed();
            return true;  ///////////need to ask chatgpt about why it put this lien, i dont udnerstand, would it work wihtout it
        } else if (response.status === 401 || response.status === 403 || !response.ok) {
            // Token refresh failed, log out the user
            logout();
            return null;
            //return false;
            // throw new Error('Token refresh failed');
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        logout();
        return null;
        //return false;
    }
}

//     try {
//         // Make request with access token as cookie
//         const response = await fetch(`${API_BASE_URL}/${url}`, {
//             ...options,
//             headers: {
//                 ...options.headers,
//                 'Content-Type': 'application/json', // Ensure Content-Type header is included..  what does this do why si it necessary???
//                 // 'X-CSRFToken': getCSRFToken(), // Include CSRF token if required by backend
//             },
//             credentials: 'include', // Include credentials (cookies) in the request
//         });

export const useFetchWithToken = () => {
    const { logout } = useAuth(); // Access logout function from AuthContext
    const navigate = useNavigate();


    const fetchWithToken = async (url, options = {}) => {
        console.log('fetchWithToken called with URL:', url);
        
	// Ensure the full URL is correctly constructed without double slashes
        const fullUrl = `${API_BASE_URL.replace(/\/$/, '')}/${url.replace(/^\//, '')}`;        

        try {
            const response = await fetch(fullUrl, {
                // const response = await fetch(fullUrl, {
                ...options,
                headers: {
                    ...options.headers,
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include credentials (cookies) in the request
            });

            if (response.status === 401 || response.status === 403) {
                console.warn('Access token expired, refreshing token...');

                if(!isRefreshing) {
                    isRefreshing = true;
                    const newToken = await refreshToken(logout, navigate);
                    isRefreshing = false;
                    if (newToken) {
                        return fetchWithToken(url, options); // Retry the original request
                    } else {
                        // No need for else block, as logout and navigate will handle the redirection
                        return new Response(JSON.stringify({ detail: 'Logged out' }), { status: 401 }); // Return a dummy response to avoid breaking the component
                    }
                }

                // If refresh is already in progress, queue the request
                return new Promise((resolve) => {  //
                    addRefreshSubscriber(() => {
                        resolve(fetchWithToken(url, options)); // Retry the request once token is refreshed
                    }); //so above w ehave the raddrefeshsubscirber which takes a callback, 
                       //so this must be what passing a callback looks like
                });
                
            }

            if (!response.ok) {
                console.error('Network response was not ok:', response);
                throw new Error('Network response was not ok');
            }
            console.log('fetchWithToken response:', response);
            return response;
        } catch (error) {
            console.error('Error in fetchWithToken:', error);
            throw error; // Rethrow the error to be caught by the caller
        }
    };

    return fetchWithToken;
};
