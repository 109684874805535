import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SquareCheck, Filter } from 'lucide-react';
import { useFetchWithToken } from '../../services/api';

const PaymentsTable = () => {

    const fetchWithToken = useFetchWithToken();
    


  return (
    <div>    
        <h1 className='text-3xl'>!!Payments Table Under Construction!!</h1>

      <div class="relative mb-12 border border-gray-200 rounded">
        <table class="w-full text-sm text-gray-600 text-left w-full rounded overflow-hidden">
          <thead class="text-xs bg-sideNavBack text-gray-800 uppercase border-b border-gray-200 ">
            <tr >
              <th className="px-4 py-3 border-r border-gray-200">No.</th>
              <th class="px-6 py-3 border-r border-gray-200">Player Name</th>

              <th class="px-6 py-3 border-r border-gray-200">
                <div className='flex'>
                <div className='flex-1'>Age</div>
                  {/* <Filter size={16}/> */}
                  <select  className="border border-gray-200 ml-2 rounded bg-slate-900 text-white">
                    <option value="">All</option>

                  </select>
                </div>
              </th>

              <th className='px-6 py-3 border-r border-gray-200'>
                <div className='flex flex-1'>
                  <div className='flex-1'>Team</div>
                  <select className="border border-gray-200 ml-2 rounded bg-slate-900 text-white">
                    <option value="">All</option>
                    <option value="tbd">TBD</option>
                                   
                  </select>
                </div>
              </th>

              <th class="px-6 py-3 border-r border-gray-200">Parent/Guardian</th>
              <th class="px-6 py-3 border-r border-gray-200">GC CID</th>
              <th class="px-6 py-3 border-r border-gray-200">Sub Status</th>
              <th class="px-6 py-3 border-r border-gray-200">Parent Email</th>
              {/* <th class="px-6 py-3 border-r border-gray-200">Mandate Url</th> */}
            </tr>
          </thead>
          <tbody>                       


                  <tr  className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="px-4 py-2 whitespace-nowrap border-r border-gray-200">gbgb</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">bgfgb</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">bgdbfgb</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">
                                    <select className={`border p-1 border-gray-200 rounded `}

                                      // onChange={(e) => handleTeamChange(player.player_id, e.target.value)}
                                       // Disable dropdown unless in edit mode
                                    >
                                      <option value="tbd">Select Team</option>
                                    
                                    </select>

                                      <button
                                        className="ml-2 text-blue-500 hover:text-blue-700"
                                        // onClick={() => toggleTeamEdit(player.player_id)}
                                      >
                                        {/* {isTeamEditable ? "Lock" : "Edit"} */}
                                      </button>
                              
                                  </td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">ybd</td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200"></td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">
                      <div className='flex'>
                  
                        <span className="flex items-center text-green-200">
                          <SquareCheck size={16} color="currentColor" className="mr-1" /> ACTIVE
                        </span>
             
                        <span className="flex items-center text-red-200">
                          <SquareCheck size={16} color="currentColor" className="mr-1" /> PENDING
                        </span>
                    
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap border-r border-gray-200">hcvbb</td>
                    {/* <td className="px-6 py-2 border-r border-gray-200">{player.mandate_url ? player.mandate_url : 'N/A'}</td> */}
                  </tr>
      
           
                <tr  className="text-gray-200 border-b border-gray-200">
                  <td className="px-4 py-2 text-slate-900 whitespace-nowrap border-r border-gray-200">cbbc</td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  <td className="px-6 py-2 whitespace-nowrap ">
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap ">
                  <select className='border border-slate-900 p-1 bg-slate-900 text-slate-900 rounded'
                    disabled // Disable dropdown unless in edit mode
                  >
                    <option value="tbd">Select Team</option>
                  </select>     
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  <td className="px-6 py-2 whitespace-nowrap ">
                    {/* <span className="flex items-center text-green-200">
                      <SquareCheck size={16} color="currentColor" className="mr-1" /> ACTIVE
                    </span> */}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap "></td>
                  {/* <td className="px-6 py-2 whitespace-nowrap border-r border-gray-600">—</td> */}
                  {/* Empty columns */}
                </tr>
       
          </tbody>
        </table>




    </div>




      
      

                    


    </div>
  );
};

export default PaymentsTable;




