import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const navigate = useNavigate();


  const persist = () => {
    const persistBeacon = localStorage.getItem('persistbeacon');
    console.log('Checking persistBeacon:', persistBeacon);

    if (persistBeacon) {
      setIsAuthenticated(true); // Set authenticated state to true if persistbeacon exists
    } else {
      setIsAuthenticated(false); // Set authenticated state to false if persistbeacon doesn't exist
    }
  };

  useEffect(() => {
    console.log('Checking persistt fucntion:');
    persist();
  }, []);

  const login = () => {
    // const role = localStorage.getItem('userRole');

    setIsAuthenticated(true);
    // if (role === 'admin') {
    //   navigate('/dashboard');
    // } else if (role === 'staff') {
    //   navigate('/home'); //whre do staff go to
    // } else if (role ==='member') {
    //   navigate('/members-dashboard')
    // }
  };

  const navigateAfterLogin = () => {
    const role = localStorage.getItem('userRole');
    if (role === 'admin') {
      navigate('/clients/memberships');
    } else if (role === 'staff') {
      navigate('/home'); //whre do staff go to
    } else if (role ==='member') {
      navigate('/members-dashboard')
    }
  }

  const logout = () => {
    setIsAuthenticated(false);
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('persistbeacon');
    navigate('/');
    console.log("Logout successful:refresh invaldiated");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, logout, login, navigateAfterLogin }}>  
      {children}
    </AuthContext.Provider>
  );
};


