import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SquareCheck } from 'lucide-react';
import { useFetchWithToken } from '../services/api';

const MemberFormSuccess = () => {
    const fetchWithToken = useFetchWithToken();
    const [playerTableData, setPlayerTableData] = useState([]);



    const getPlayerTableData = async () => {
        try {
          const response = await fetchWithToken('api/membership-table/')
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log('101010 Memebrship table data:', data); // Log jobs to verify structure
          setPlayerTableData(data);

        } catch (error) {
          console.error('Error fetching membershiptable data:', error);
        }
      };

      useEffect(() => {
        getPlayerTableData();
      }, [])

  return (
    <div>

      <div className='flex flex-row justify-start mb-12'>
        {/* <div className='px-24 py-8  border border-borderc rounded w-1/3 hover:bg-blue-300 mr-6'>players</div> */}
        {/* <div className='px-24 py-8 border w-1/4 bg-blue-500 hover:bg-blue-300'>outstanding</div> */}
        {/* <Link to='/create-mandate' className='px-24 py-2 border border-borderc rounded font-medium w-1/3 bg-secnavbg hover:bg-blue-500 hover:text-white text-center'>Create New Mandate</Link> */}

        {/* <div className='px-24 py-8 border border-borderc rounded w-1/3 hover:bg-blue-300 ml-6'>create mandate</div> */}
      </div> 

      <div>
        <div class="relative overflow-x-auto rounded">
            <table class="w-full border border-borderc text-sm text-left rtl:text-right">
                <thead class="text-xs text-gray-700 uppercase border-b border-borderc bg-secnavbg">
                    <tr className=''>
                        <th scope="col" class="px-6 py-5 border-r border-borderc">
                            Player Name
                        </th>
                        <th scope="col" class="px-6 py-5 border-r border-borderc">
                            Current Team
                        </th>
                        <th scope="col" class="px-6 py-5 border-r border-borderc">
                            Parent/Gaurdian
                        </th>
                        <th scope="col" class="px-6 py-5 border-r border-borderc">
                            Mandate Status
                        </th>
                        <th scope="col" class="px-6 py-5 border-r border-borderc">
                            Subscription Status
                        </th>
                        <th scope="col" class="px-6 py-5 border-r border-borderc">
                            Parent Email
                        </th>
                        <th scope="col" class="px-6 py-5 border-r border-borderc">
                            Mandate Url
                        </th>
                        {/* <th scope="col" class="px-6 py-5 border-r">
                            <span class="sr-only">Edit</span>
                        </th> */}
                    </tr>
                </thead>
                {/* <tbody>
                    <tr class="bg-white border-b  hover:bg-gray-50">
                        <th scope="row" class="px-6 py-4 font-medium  whitespace-nowrap border-r border-borderc">
                            Mo Salad
                        </th>
                        <td class="px-6 py-4 border-r border-borderc">
                            Silver
                        </td>
                        <td class="px-6 py-4 border-r border-borderc">
                            Laptop
                        </td>
                        <td class="px-6 py-4 border-r border-borderc flex">
                            <span className='flex mr-2'><SquareCheck size={16} color="currentColor" className="text-green-600 hover:text-white mr-1" />ACTIVE</span>
                        </td>
                        <td class="px-6 py-4 border-r border-borderc">
                            <span className='flex'><SquareCheck size={16} color="currentColor" className="text-red-600 hover:text-white mr-1"/>PENDING</span>
                        </td>
                        <td class="px-6 py-4 border-r border-borderc">
                            25/12/0001
                        </td>
                    </tr>
                    
                </tbody> */}
                <tbody>
                    {playerTableData.map((player, index) => {
                        const subscriptionStatus = player.subscription && Object.keys(player.subscription).length ? "Complete" : "Incomplete";
                        return (
                            <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{player.child_name}</td>
                                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{player.team_current}</td>
                                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{player.mandate_status}</td>
                                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200 flex">
                                    {subscriptionStatus === "Complete" ? (
                                        <span className="flex items-center text-green-600">
                                            <SquareCheck size={16} color="currentColor" className="mr-1" /> ACTIVE
                                        </span>
                                    ) : (
                                        <span className="flex items-center text-red-600">
                                            <SquareCheck size={16} color="currentColor" className="mr-1" /> PENDING
                                        </span>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{player.parent_name}</td>
                                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{player.parent_email}</td>
                                <td className="px-6 py-4 border-r border-gray-200 ">
                                    {/* {player.auth_url ? (
                                        <p href={player.auth_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                                            {player.auth_url}
                                        </p>
                                    ) : (
                                        "N/A"
                                    )} */}
                                    {player.auth_url ? (
                                        <p target="_blank" rel="noopener noreferrer" className="">
                                        {/* add a copy paste button */}
                                            {player.auth_url}
                                        </p>
                                    ) : (
                                        "N/A"
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
      </div>


    </div>
  );
};

export default MemberFormSuccess;
