import React from 'react';
import { Link } from 'react-router-dom';

const MembersDashboard = () => {
  console.log('Rendering Dashboard component');
  return (
    <div>
      
      <div className='flex flex-col mx-auto'>
        <div className='bg-gray-200 p-10 rounded-2xl mb-8'>
          <h1 className='text-3xl mb-8 font-medium text-gray-900'>Welcome "name of parent"</h1>
          {/* <h1 className='text-xl mb-8 text-gray-900'>You have no outstanding task to complete</h1> */}
          <div className='flex flex-col'>
            <div className='border border-indigo-300 mb-6'>parents div/details</div>
            <div>
              {/* do a map here of players to do wiht parent */}
              <div className='border border-indigo-300 mb-6'>
                player details(not full detaisl jsut the stuff <br></br>they entered on outsid emember form)
                <br/> age<br/>name
              </div>
              <div className='border border-indigo-300 mb-6'>
                player details(not full detaisl jsut the stuff <br></br>they entered on outsid emember form)
                <br/> age<br/>name
              </div>
            </div>
          </div>
        </div>
        
        {/* map over this for each player */}
        <div className='bg-gray-200 p-10 rounded-2xl w-full mb-8'>
        <div  >
            <h1 className='text-3xl mb-8 font-medium text-gray-900'>Member questionnaire for "player name"</h1>
            {/* <h1 className='text-xl mb-8 text-gray-900'>box for details of parent/gaurdian</h1> */}
            <h1 className='text-xl mb-8 text-gray-900'>Follow link to complete member questionnaire</h1>

            <a href="https://www.google.co.uk/" target='_blank'>link</a>
            {/* <h1 className='text-3xl mb-8 font-medium text-gray-900'>place to add a player</h1> */}

            <p className='mt-6'>Once you have completed this form please tick this box to confirm completion</p>
        </div>
        </div>

        
        {/* map over this for each player */}
        <div className='bg-green-200 p-10 rounded-2xl w-full mb-8'>
        <a className="w-full" href="https://www.google.co.uk/" target='_blank'>
            <h1 className='text-3xl mb-8 font-medium text-gray-900'>Medical questionnaire for "player name"</h1>
            {/* <h1 className='text-xl mb-8 text-gray-900'>box for details of parent/gaurdian</h1> */}
            <h1 className='text-xl mb-8 text-gray-900'>box for details of each player, eahc player gets a box
            whiting each players box will be soem shrot info and also a link ot hat paeyr socmpeterd 
            memebr quetsionnaie plus the meiducal questionnai</h1>

            {/* <h1 className='text-3xl mb-8 font-medium text-gray-900'>place to add a player</h1> */}
        </a>
        </div>
        

        

       
      
      </div>
    </div>
  );
};

export default MembersDashboard;
