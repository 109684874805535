// src/components/Header.js
import React, { useState } from 'react';
import { Link, NavLink} from 'react-router-dom';
import { House, Handshake, Users } from 'lucide-react';
import useLogout from '../logout';
import pinevillalogo from '../../assets/pinevillalogo.png';
import GlassFace from '../../assets/GlassFace.png'
import avatarblank from '../../assets/avatarblank.png'

const AdminHeader = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLogout = useLogout();

  return (
    // <header className="text-gray-100">
    //   <div className="text-gray-200">

    //     <div className='container mx-auto flex justify-between items-center'>

    //       <div className="text-lg font-medium">
    //         PineVillaAdmin
    //       </div>
          
    //       <div className='relative'>
    //       <div onClick={toggleDropdown} className="hover:text-indigo-500 bg-slate-900 py-5 px-5">Repace with Name</div>
    //       {isOpen && (
    //       <div id="dropdown" className="absolute right-0 z-10 border border-gray-300 bg-slate-900 divide-y divide-gray-100 rounded-lg shadow whitespace-nowrap">
    //         <ul className="py-2 px-4 text-sm " aria-labelledby="dropdownDefaultButton">
    //           {/* <li>
    //             <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 ">Dashboard</a>
    //           </li>
    //           <li>
    //             <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
    //           </li>
    //           <li>
    //             <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
    //           </li> */}
    //           <li>
    //             <button onClick={handleLogout} className="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</button>
    //           </li>
    //         </ul>
    //       </div>
    //       )}
    //       </div>

    //     </div>
    //   </div>

    //   <div className='bg-slate-900'>
    //     <nav className='container mx-auto py-4'>
    //       <ul className="flex space-x-8">
    //         <li>
    //           <NavLink to="/dashboard" className={({ isActive }) =>`text-sectextGray flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
    //             <House size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Dashboard
    //           </NavLink>
    //         </li>
    //         <li>
    //           <NavLink to="/memberships" className={({ isActive }) =>`text-sectextGray flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
    //             <Handshake size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Memberships
    //           </NavLink>
    //         </li>
    //         {/* <li>
    //           <NavLink to="/staff" className={({ isActive }) =>`text-sectextGray flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
    //             <Users size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Staff
    //           </NavLink>
    //         </li> */}
    //         {/* <li>
    //           <NavLink to="/dashboard" className="text-sectextGray  text-sm hover:text-blue-700">Dashboard</NavLink>
    //         </li> */}

    //       </ul>
    //     </nav>
    //   </div>
      
    // </header>
    <header className="sticky top-0 z-50 w-full bg-headerBar text-gray-100 py-2">
      <div className="text-gray-200">

        <div className='mx-6 flex justify-between items-center'>

          <div className="text-lg font-medium flex items-center">
            <img src={pinevillalogo} className='h-12 mr-2'/>
            <h1>Pine Villa Management</h1>
          </div>
          
          <div className='relative'>
          <div onClick={toggleDropdown} className="flex items-center hover:text-indigo-500 px-2">
            <img src={avatarblank} className='h-8 mr-2'/>
            <h1>Name LastName</h1>
          </div>
          {isOpen && (
          <div id="dropdown" className="absolute right-0 z-10 border border-gray-300 bg-slate-900 divide-y divide-gray-100 rounded-lg shadow whitespace-nowrap">
            <ul className="py-2 px-4 text-sm " aria-labelledby="dropdownDefaultButton">
              {/* <li>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 ">Dashboard</a>
              </li>
              <li>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
              </li>
              <li>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
              </li> */}
              <li>
                <button onClick={handleLogout} className="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</button>
              </li>
            </ul>
          </div>
          )}
          </div>

        </div>
      </div>

      {/* <div className='bg-slate-900'>
        <nav className='container mx-auto py-4'>
          <ul className="flex space-x-8">
            <li>
              <NavLink to="/dashboard" className={({ isActive }) =>`text-sectextGray flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                <House size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/memberships" className={({ isActive }) =>`text-sectextGray flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                <Handshake size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Memberships
              </NavLink>
            </li>
            

          </ul>
        </nav>
      </div> */}
      
    </header>
  );
};

export default AdminHeader;



{/* <li>
              <NavLink to="/staff" className={({ isActive }) =>`text-sectextGray flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                <Users size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Staff
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink to="/dashboard" className="text-sectextGray  text-sm hover:text-blue-700">Dashboard</NavLink>
            </li> */}